<template>
  <div class="jrlsecurty" id="jrlsecurty">
    <!-- 标题部分 -->
    <div class="title-hang">
      <div class="fl">
        <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
      </div>
      <div class="zhuangshizb fl"></div>
      <div class="zhuangshizj fl">{{ projectData.project }}_安全设置</div>
      <div class="zhuangshiyb fl">
        <div class="fl">
          <dv-decoration-1
            style="width: 14vw; height: 3vh; margin-top: 0vh; margin-left: 16vw"
          />
        </div>
      </div>
    </div>
    <!-- 上半部分 -->
    <div class="topmain">
      <div class="topmain-row1 fl">
        <div class="htjg">安全</div>
        <div class="htjg" >生产</div>
        <div class="htjg">阀位</div>
        <div class="htjg">设置</div>
      </div> 
      <div class="topmain-row2 fl"> 
        <div class="topmain-row2-col1">均热段煤气阀门上限</div>
        <div class="topmain-row2-col1">均热段空气阀门上限</div>
        <div class="topmain-row2-col1">均热段煤烟阀门上限</div> 
        <div class="topmain-row2-col1">均热段空烟阀门上限</div>
        <div class="topmain-row2-col1">二加热段煤气阀门上限</div>
        <div class="topmain-row2-col1">二加热段空气阀门上限</div>
        <div class="topmain-row2-col1">二加热段煤烟阀门上限</div>
        <div class="topmain-row2-col1">二加热段空烟阀门上限</div>
        <div class="topmain-row2-col1">鼓风机频率上限</div>
        <div class="topmain-row2-col1">空烟气引风频率上限</div>
        <div class="topmain-row2-col11">均热段煤气设定上限</div>
      </div>
      <div class="topmain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN2.OutT,
                    '均热段煤气阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN2',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN2.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN1.OutT,
                    '均热段空气阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN1',
                   
                  )
                "
        
        >
          {{ infoList.G_MCSMAN__p__MAN1.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN4.OutT,
                    '均热段煤烟阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN4',
                   
                  )
                "
        
        >
          {{ infoList.G_MCSMAN__p__MAN4.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
       @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN3.OutT,
                    '均热段空烟阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN3',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN3.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN10.OutT,
                    '二加热段煤气阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN10',
                   
                  )
                "
        
        >
          {{ infoList.G_MCSMAN__p__MAN10.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
             @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN9.OutT,
                    '二加热段空气阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN9',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN9.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
       @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN12.OutT,
                    '二加热段煤烟阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN12',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN12.OutT }}

        </div>
        <div
          class="topmain-row3-col1"
         @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN11.OutT,
                    '二加热段空烟阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN11',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN11.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN16.OutT,
                    '鼓风机频率上限',
                     'OutT',
                    'G_MCSMAN__p__MAN16',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN16.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN13.OutT,
                    '空烟气引风频率上限',
                     'OutT',
                    'G_MCSMAN__p__MAN13',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN13.OutT }}
        </div>
        <div
          class="topmain-row3-col11"
   @click="
                  toIpt(
                    infoList.G_TEMP.MQ11SPH,
                    '均热段煤气设定上限',
                     'MQ11SPH',
                    'G_TEMP'
                   
                  )
                "
        >
          {{ infoList.G_TEMP.MQ11SPH }}
        </div>
        </div>
  
      <div class="topmain-row2 fl">
        <div class="topmain-row2-col1">均热段煤气阀门下限</div>
        <div class="topmain-row2-col1">均热段空气阀门下限</div>
        <div class="topmain-row2-col1">均热段煤烟阀门下限</div>
        <div class="topmain-row2-col1">均热段空烟阀门下限</div>
        <div class="topmain-row2-col1">二加热段煤气阀门下限</div>
        <div class="topmain-row2-col1">二加热段空气阀门下限</div>
        <div class="topmain-row2-col1">二加热段煤烟阀门下限</div>
        <div class="topmain-row2-col1">二加热段空烟阀门下限</div>
        <div class="topmain-row2-col1">鼓风机频率下限</div>
        <div class="topmain-row2-col1">空烟气引风频率下限</div>
        <div class="topmain-row2-col11">三加热段煤气设定上限</div>
      </div>
     <div class="topmain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN2.OutB,
                    '均热段煤气阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN2',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN2.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN1.OutB,
                    '均热段空气阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN1',
                   
                  )
                "
        
        >
          {{ infoList.G_MCSMAN__p__MAN1.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN4.OutB,
                    '均热段煤烟阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN4',
                   
                  )
                "
        
        >
          {{ infoList.G_MCSMAN__p__MAN4.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
       @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN3.OutB,
                    '均热段空烟阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN3',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN3.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN10.OutB,
                    '二加热段煤气阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN10',
                   
                  )
                "
        
        >
          {{ infoList.G_MCSMAN__p__MAN10.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
             @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN9.OutB,
                    '二加热段空气阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN9',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN9.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
       @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN12.OutB,
                    '二加热段煤烟阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN12',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN12.OutB }}

        </div>
        <div
          class="topmain-row3-col1"
         @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN11.OutB,
                    '二加热段空烟阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN11',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN11.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN16.OutB,
                    '鼓风机频率下限',
                     'OutB',
                    'G_MCSMAN__p__MAN16',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN16.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN13.OutB,
                    '空烟气引风频率下限',
                     'OutB',
                    'G_MCSMAN__p__MAN13',
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN13.OutB }}
        </div>
        <div
          class="topmain-row3-col11"
   @click="
                  toIpt(
                    infoList.G_TEMP.MQ21SPH,
                    '三加热段煤气设定上限',
                     'MQ21SPH',
                    'G_TEMP'
                   
                  )
                "
        >
          {{ infoList.G_TEMP.MQ21SPH }}
        </div>
        </div>

      <div class="topmain-row2 fl">
   <div class="topmain-row2-col1">三加热段煤气阀门上限</div>
        <div class="topmain-row2-col1">三加热段空气阀门上限</div>
        <div class="topmain-row2-col1">三加热段煤烟阀门上限</div>
        <div class="topmain-row2-col1">三加热段空烟阀门上限</div>
        <div class="topmain-row2-col1">一加热段煤气阀门上限</div>
        <div class="topmain-row2-col1">一加热段空气阀门上限</div>
        <div class="topmain-row2-col1">一加热段煤烟阀门上限</div>
        <div class="topmain-row2-col1">一加热段空烟阀门上限</div>
        <div class="topmain-row2-col1">煤气总管阀门上限</div>
        <div class="topmain-row2-col1">煤烟气引风频率上限</div>
        <div class="topmain-row2-col11">二加热段煤气设定上限</div>
      </div>
      <div class="topmain-row3 fl">
        <div
          class="topmain-row3-col1"
         @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN6.OutT,
                    '三加热段煤气阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN6'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN6.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
             @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN5.OutT,
                    '三加热段空气阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN5'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN5.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN8.OutT,
                    '三加热段煤烟阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN8'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN8.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
              @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN7.OutT,
                    '三加热段空烟阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN7'
                   
                  )
                "
     
        >
          {{ infoList.G_MCSMAN__p__MAN7.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
             @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN21.OutT,
                    '一加热段煤气阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN21'
                   
                  )
                "
        
        >
          {{ infoList.G_MCSMAN__p__MAN21.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
       @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN20.OutT,
                    '一加热段空气阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN20'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN20.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
            @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN23.OutT,
                    '一加热段煤烟阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN23'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN23.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN22.OutT,
                    '一加热段空烟阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN22'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN22.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN24.OutT,
                    '煤气总管阀门上限',
                     'OutT',
                    'G_MCSMAN__p__MAN24'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN24.OutT }}
        </div>
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN14.OutT,
                    '煤烟气引风频率上限',
                     'OutT',
                    'G_MCSMAN__p__MAN14'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN14.OutT }}
        </div>
        <div
          class="topmain-row3-col11"
         @click="
                  toIpt(
                    infoList.G_TEMP.MQ31SPH,
                    '二加热段煤气设定上限',
                    'MQ31SPH',
                    'G_TEMP',
                    
                  )
                "
        >
          {{ infoList.G_TEMP.MQ31SPH }}
        </div>
      </div>
      <div class="topmain-row2 fl">
   <div class="topmain-row2-col1">三加热段煤气阀门下限</div>
        <div class="topmain-row2-col1">三加热段空气阀门下限</div>
        <div class="topmain-row2-col1">三加热段煤烟阀门下限</div>
        <div class="topmain-row2-col1">三加热段空烟阀门下限</div>
        <div class="topmain-row2-col1">一加热段煤气阀门下限</div>
        <div class="topmain-row2-col1">一加热段空气阀门下限</div>
        <div class="topmain-row2-col1">一加热段煤烟阀门下限</div>
        <div class="topmain-row2-col1">一加热段空烟阀门下限</div>
        <div class="topmain-row2-col1">煤气总管阀门下限</div>
        <div class="topmain-row2-col1">煤烟气引风频率下限</div>
        <div class="topmain-row2-col11">一加热段煤气设定上限</div>
      </div>
      <div class="topmain-row3 fl">
        <div
          class="topmain-row3-col1"
         @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN6.OutB,
                    '三加热段煤气阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN6'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN6.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
             @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN5.OutB,
                    '三加热段空气阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN5'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN5.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN8.OutB,
                    '三加热段煤烟阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN8'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN8.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
              @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN7.OutB,
                    '三加热段空烟阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN7'
                   
                  )
                "
     
        >
          {{ infoList.G_MCSMAN__p__MAN7.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
             @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN21.OutB,
                    '一加热段煤气阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN21'
                   
                  )
                "
        
        >
          {{ infoList.G_MCSMAN__p__MAN21.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
       @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN20.OutB,
                    '一加热段空气阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN20'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN20.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
            @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN23.OutB,
                    '一加热段煤烟阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN23'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN23.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN22.OutB,
                    '一加热段空烟阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN22'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN22.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN24.OutB,
                    '煤气总管阀门下限',
                     'OutB',
                    'G_MCSMAN__p__MAN24'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN24.OutB }}
        </div>
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_MCSMAN__p__MAN14.OutB,
                    '煤烟气引风频率下限',
                     'OutB',
                    'G_MCSMAN__p__MAN14'
                   
                  )
                "
        >
          {{ infoList.G_MCSMAN__p__MAN14.OutB }}
        </div>
        <div
          class="topmain-row3-col11"
         @click="
                  toIpt(
                    infoList.G_TEMP.MQ41SPH,
                    '一加热段煤气设定上限',
                    'MQ41SPH',
                    'G_TEMP',
                    
                  )
                "
        >
          {{ infoList.G_TEMP.MQ41SPH }}
        </div>
      </div>
    </div>
    <div class="bottommain">
      <div class="bottom-row1 fl">
        <div class="htjg1 htjg">安全</div>
        <div class="htjg1">生产</div>
        <div class="htjg1">报警</div>
        <div class="htjg1">参数</div>
        <div class="htjg1">设置</div>
      </div>
      <div class="bottommain-row2 fl">
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
                           @click="
                  toIpt(
                    infoList.G_ALARM.T11EN,
                    '  均热段温度上限',
                     'T11EN',
                    'G_ALARM',
                   
                  )
                "
          >

               <el-switch  v-model="infoList.G_ALARM.T11EN"
                 
                  active-color="#29DFAE"
                  inactive-color="red"
                    disabled>
                </el-switch>
          </div>
          均热段温度上限
        </div>
       <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T21EN,
                '三加热段温度上限',
                'T21EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T21EN"
              active-color="#29DFAE"
              inactive-color="red"
               disabled
            >
            </el-switch>
          </div>
          三加热段温度上限
        </div>
               <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T31EN,
                '二加热段温度上限',
                'T31EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T31EN"
              active-color="#29DFAE"
              inactive-color="red"
                disabled
            >
            </el-switch>
          </div>
          二加热段温度上限
        </div>
               <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T41EN,
                '一加热段温度上限',
                'T41EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T41EN"
              active-color="#29DFAE"
              inactive-color="red"
                disabled
            >
            </el-switch>
          </div>
          一加热段温度上限
        </div>
                       <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T121EN,
                '均热空烟温度上限',
                'T121EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T121EN"
              active-color="#29DFAE"
              inactive-color="red"
                disabled
            >
            </el-switch>
          </div>
          均热空烟温度上限
        </div>
                              <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T221EN,
                '三加空烟温度上限',
                'T221EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T221EN"
              active-color="#29DFAE"
              inactive-color="red"
                disabled
            >
            </el-switch>
          </div>
          三加空烟温度上限
        </div>
                              <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T321EN,
                '二加空烟温度上限',
                'T321EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T321EN"
              active-color="#29DFAE"
              inactive-color="red"
                disabled
            >
            </el-switch>
          </div>
          二加空烟温度上限
        </div>
                              <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T421EN,
                '一加空烟温度上限',
                'T421EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T421EN"
              active-color="#29DFAE"
              inactive-color="red"
                disabled
            >
            </el-switch>
          </div>
          一加空烟温度上限
        </div>
        



      </div>
      <div class="bottommain-row3 fl">

               <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T11H,
                    '均热段温度上限',
                     'T11H',
                    'G_ALARM'
                   
                  )
                "
        >
         {{infoList.G_ALARM.T11H}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T21H,
                    '三加热段温度上限',
                     'T21H',
                    'G_ALARM'
                   
                  )
                "
        >
         {{infoList.G_ALARM.T21H}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T31H,
                    '二加热段温度上限',
                    'T31H',
                    'G_ALARM'
                    
                  )
                "
        >
         {{infoList.G_ALARM.T31H}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T41H,
                    '一加热段温度上限',
                     'T41H',
                    'G_ALARM',
                   
                  )
                "
        >
         {{infoList.G_ALARM.T41H}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T121H,
                    '均热空烟温度上限',
                     'T121H',
                    'G_ALARM'
                   
                  )
                "
        >
         {{infoList.G_ALARM.T121H}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T221H,
                    '三加空烟温度上限',
                    'T221H',
                    'G_ALARM',
                    
                  )
                "
        >
         {{infoList.G_ALARM.T221H}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T321H,
                    '二加空烟温度上限',
                     'T321H',
                    'G_ALARM',
                   
                  )
                "
        >
         {{infoList.G_ALARM.T321H}}
        </div>
            <div
          class="topmain-row3-col11"
           @click="
                  toIpt(
                    infoList.G_ALARM.T421H,
                    '一加空烟温度上限',
                    'T421H',
                    'G_ALARM',
                    
                  )
                "
        >
         {{infoList.G_ALARM.T421H}}
        </div>

      </div>
            <div class="bottommain-row2 fl">
        <div class="topmain-row2-col1">

          均热段温度下限
        </div>
                <div class="topmain-row2-col1">

       三加热段温度下限
        </div>
                <div class="topmain-row2-col1">

   二加热段温度下限
        </div>
                <div class="topmain-row2-col1">
一加热段温度下限
        </div>
                <div class="topmain-row2-col1">
均热空烟温度下限
        </div>
                <div class="topmain-row2-col1">
三加空烟温度下限
        </div>
                <div class="topmain-row2-col11">
二加空烟温度下限
        </div>
        <div class="topmain-row2-col1">
一加空烟温度下限
        </div>

      </div>
      <div class="bottommain-row3 fl">

               <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T11L,
                    '均热段温度下限',
                    'T11L',
                    'G_ALARM'
                    
                  )
                "
        >
         {{infoList.G_ALARM.T11L}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T21L,
                    '三加热段温度下限',
                     'T21L',
                    'G_ALARM'
                   
                  )
                "
        >
         {{infoList.G_ALARM.T21L}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T31L,
                    '二加热段温度下限',
                     'T31L',
                    'G_ALARM'
                   
                  )
                "
        >
         {{infoList.G_ALARM.T31L}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T41L,
                    '一加热段温度下限',
                     'T41L',
                    'G_ALARM'
                   
                  )
                "
        >
         {{infoList.G_ALARM.T41L}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T121L,
                    '均热空烟温度下限',
                    'T121L',
                    'G_ALARM',
                    
                  )
                "
        >
         {{infoList.G_ALARM.T121L}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T221L,
                    '三加空烟温度下限',
                    'T221L',
                    'G_ALARM',
                    
                  )
                "
        >
         {{infoList.G_ALARM.T221L}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T321L,
                    '二加空烟温度下限',
                     'T321L',
                    'G_ALARM',
                   
                  )
                "
        >
         {{infoList.G_ALARM.T321L}}
        </div>
            <div
          class="topmain-row3-col11"
           @click="
                  toIpt(
                    infoList.G_ALARM.T421L,
                    '一加空烟温度下限',
                     'T421L',
                    'G_ALARM',
                   
                  )
                "
        >
         {{infoList.G_ALARM.T421L}}
        </div>

      </div>
            <div class="bottommain-row2 fl">
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.PMQEN,
                '煤气总管压力上限',
                'PMQEN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.PMQEN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
            
          </div>
          煤气总管压力上限
        </div>
                <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.PKQEN,
                '空气总管压力上限',
                'PKQEN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.PKQEN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
         空气总管压力上限
        </div>
                <div class="topmain-row2-col1">

        </div>
                <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.YHZEN1,
                '优化站异常报警',
                'YHZEN1',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.YHZEN1"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
        优化站异常报警
        </div>
                <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T122EN,
                '均热煤烟温度上限',
                'T122EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T122EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          均热煤烟温度上限
        </div>
                <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T222EN,
                '三加煤烟温度上限',
                'T222EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T222EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          三加煤烟温度上限
        </div>
                <div class="topmain-row2-col11">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T322EN,
                '二加煤烟温度上限',
                'T322EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T322EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
         二加煤烟温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.G_ALARM.T422EN,
                '一加煤烟温度上限',
                'T422EN',
                'G_ALARM'
              )
            "
          >
            <el-switch
              v-model="infoList.G_ALARM.T422EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
         一加煤烟温度上限
        </div>

      </div>
      <div class="bottommain-row3 fl">
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.PMQH,
                    '煤气总管压力上限',
                     'PMQH',
                    'G_ALARM',
                   
                  )
                "
        >
         {{infoList.G_ALARM.PMQH}}
        </div>
               <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.PKQH,
                    '空气总管压力上限',
                      'PKQH',
                    'G_ALARM',
                  
                  )
                "
        >
         {{infoList.G_ALARM.PKQH}}
        </div>

       <div
          class="topmain-row3-col1"
          
        >
        </div>

       <div
          class="topmain-row3-col1"
         
        >
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T122H,
                    '均热煤烟温度上限',
                     'T122H',
                    'G_ALARM',
                   
                  )
                "
        >
         {{infoList.G_ALARM.T122H}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T222H,
                    '三加煤烟温度上限',
                    'T222H',
                    'G_ALARM',
                    
                  )
                "
        >
         {{infoList.G_ALARM.T222H}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T322H,
                    '二加煤烟温度上限',
                    'T322H',
                    'G_ALARM',
                    
                  )
                "
        >
         {{infoList.G_ALARM.T322H}}
        </div>

       <div
          class="topmain-row3-col11"
           @click="
                  toIpt(
                    infoList.G_ALARM.T422H,
                    '一加煤烟温度上限',
                    'T422H',
                    'G_ALARM',
                    
                  )
                "
        >
         {{infoList.G_ALARM.T422H}}
        </div>


      </div>
            <div class="bottommain-row2 fl">
        <div class="topmain-row2-col1">
        
          煤气总管压力下限
        </div>
                <div class="topmain-row2-col1">
       
          空气总管压力下限
        </div>
                <div class="topmain-row2-col1">
        
        </div>
                <div class="topmain-row2-col1">
        
        </div>
                <div class="topmain-row2-col1">
          
          均热煤烟温度下限
        </div>
                <div class="topmain-row2-col1">
        
          三加煤烟温度下限
        </div>
                <div class="topmain-row2-col11">
     
          二加煤烟温度下限
        </div>
        <div class="topmain-row2-col1">
        
         一加煤烟温度下限
        </div>

      </div>
      <div class="bottommain-row3 fl">
        <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.PMQL,
                    '煤气总管压力下限',
                    'PMQL',
                    'G_ALARM'
                    
                  )
                "
        >
         {{infoList.G_ALARM.PMQL}}
        </div>
               <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.PKQL,
                    '空气总管压力下限',
                     'PKQL',
                    'G_ALARM',
                   
                  )
                "
        >
         {{infoList.G_ALARM.PKQL}}
        </div>

       <div
          class="topmain-row3-col1"
          
        >
        </div>

       <div
          class="topmain-row3-col1"
         
        >
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T122L,
                    '均热煤烟温度下限',
                     'T122L',
                    'G_ALARM'
                   
                  )
                "
        >
         {{infoList.G_ALARM.T122L}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T222L,
                    '三加煤烟温度下限',
                    'T222L',
                    'G_ALARM'
                    
                  )
                "
        >
         {{infoList.G_ALARM.T222L}}
        </div>

       <div
          class="topmain-row3-col1"
           @click="
                  toIpt(
                    infoList.G_ALARM.T322L,
                    '二加煤烟温度下限',
                    'T322L',
                    'G_ALARM'
                    
                  )
                "
        >
         {{infoList.G_ALARM.T322L}}
        </div>

       <div
          class="topmain-row3-col11"
           @click="
                  toIpt(
                    infoList.G_ALARM.T422L,
                    '一加煤烟温度下限',
                    'T422L',
                    'G_ALARM'
                    
                  )
                "
        >
         {{infoList.G_ALARM.T422L}}
        </div>


      </div>
    </div>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
     <JRLcysz v-if="flag == 4 ? (isComShow = true) : (isComShow = false)" @sendStatus = 'isClose' :infoList = 'infoList'></JRLcysz>
  </div>
</template>
<script>
import Historical from "@/components/Historical";
import inputVal from "@/components/inputVal"; //输入框组件
import JRLcysz from "@/components/JRL_cysz.vue"; //常用设置组件
export default {
  name: "index",
  components: {
    Historical,
    inputVal,
    JRLcysz
  },
     props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
  data: () => {
    return {
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
    };
  },
   watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		this.authInfo = JSON.parse(localStorage.getItem("autharr"))
        this.$bus.$on("sendMsg", (msg,msg1) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
    });
  },
  computed: {},
  mounted() {},
  methods: {
            DataJson(data){
      let strNode = data[0]
      let strMark = data[1]
        this.$nextTick(() => {
       this.infoList[strNode][strMark] = data[2]     
      });
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if(!this.authInfo[1]){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
        console.log(str.charAt(str.length-1))
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无操作权限');
				}
			}else{
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
      
    },
    closeCompon() {
      this.$emit("sendStatus", "Param01", false);
    },
    isClose(){
     this.flag = 2,this.$bus.$emit("footersendMsg", 2)
    },
    isClose1() {
      this.isHshow = false;
    },
    toCompon(key, name, name2, name3, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (
            (this.isMshow = true),
            (this.Manualname = name),
            (this.Manualnode = name2),
            (this.ManualAname = name3),
            (this.Manualtitname = titname)
          );
        case 1:
          return (
            (this.isFshow = true),
            (this.Firstcontrolname = name),
            (this.Firstcontrolnode = name2),
            (this.Firstcontroltitname = titname)
          );
        case 2:
          return (this.isHshow = true);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.jrlsecurty {
  width: 96vw;
  height: auto;
  margin-left: 2vw;
  //  background: #e9f7ff;
  background-image: linear-gradient(#0e3449 60%, #0e3146 61%, #0c2a38 100%),
    linear-gradient(#0e3449, #0e3449);
  background-blend-mode: normal, normal;
  overflow: hidden;
  //  cursor:move;
  border-radius: 5px;
  .ziti {
    font-size: 0.9vw;
  }
  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width: 96vw;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
  }
  .conter1 {
    margin-left: 1vw;
    width: 17vw;
    height: 29vh;
    line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate01.png");
    background-size: 100% 100%;
  }
  .conter {
    height: 29vh;
    width: 100%;
    margin-top: 3vh;
  }
  .conter1 {
    margin-left: 1vw;
    width: 17vw;
    height: 29vh;
    line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate01.png");
    background-size: 100% 100%;
  }
  .conter2 {
    margin-left: 1vw;
    width: 29vw;
    height: 29vh;
    // line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate03.png");
    background-size: 100% 100%;
  }
  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }
  .divcei {
    margin-top: 3vh;
    margin-left: 4vw;
    width: 9vw;
    height: 20vh;
    line-height: 20vh;
    background-image: url("~@/assets/images/rfl_evaluate02.png");
    background-size: 100% 100%;
    font-size: 2vw;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0vw;
    color: #ffffff;
    text-align: center;
  }
  .divjjx {
    margin-top: 3vh;
    margin-left: 4vw;
    width: 9vw;
    height: 26vh;
    line-height: 26vh;
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0vw;
    color: #ffffff;
    text-align: center;
  }
  .zhuangshizb {
    width: 14vw;
    height: 3vh;
    background-image: url("~@/assets/images/rfl_evaluate06.png");
    background-size: 100% 100%;
    margin-left: 3.5vw;
    margin-top: 1.6vh;
  }
  .zhuangshizj {
    width: 26vw;
    height: 5vh;
    line-height: 5vh;
    margin-top: 1vh;
    text-align: center;
    background-image: url("~@/assets/images/rfl_evaluate05.png");
    background-size: 100% 100%;
    margin-left: 5vw;
    font-family: MicrosoftYaHei;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }
  .zhuangshiyb {
    width: 14vw;
    height: 3vh;
    background-image: url("~@/assets/images/rfl_evaluate04.png");
    background-size: 100% 100%;
    margin-left: 5vw;
    margin-top: 1.6vh;
  }
  .title-hang {
    width: 100%;
  }
  .mainall {
    margin-top: 3vh;
  }
  .topmain {
    width: 94.4vw;
    height: 44vh;
    margin-top: 2vh;
    border: 1px #18394d solid;
  }
  .bottommain {
    width: 94.4vw;
    height: 32vh;
    margin-top: 1vh;
    border: 1px #18394d solid;
  }
  .htjg {
    height: 6vh;
  }
  .topmain-row1 {
    width: 5vw;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #fff;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
  }
  .bottom-row1 {
    width: 5vw;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #fff;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
  }
  .topmain-row2 {
    width: 15vw;
    font-size: 1vw;
    height: 44vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #70c2d6;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
    border-left: 1px #18394d solid;
  }
  .bottommain-row2 {
    width: 15vw;
    font-size: 1vw;
    height: 32vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #70c2d6;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
    border-left: 1px #18394d solid;
  }
  .htjg {
    margin-top: 3vh;
  }
  .htjg1 {
    height: 5.5vh;
    line-height: 5.5vh;
  }
  .topmain-row2-col1 {
    width: 15vw;
    height: 3.89vh;
    line-height: 3.89vh;
    border-bottom: 1px #18394d solid;
  }
  .topmain-row2-col11 {
    width: 15vw;
    height: 3.89vh;
    line-height: 3.89vh;
    //  border-bottom:1px #18394d solid
  }
  .topmain-row3 {
    width: 7.2vw;
    font-size: 1vw;
    height: 44vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #fff;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
    border-left: 1px #18394d solid;
  }
  .bottommain-row3 {
    width: 7.2vw;
    font-size: 1vw;
    height: 32vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #fff;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
    border-left: 1px #18394d solid;
  }
  .topmain-row3-col1 {
    width: 7.2vw;
    height: 3.89vh;
    line-height: 3.89vh;
    border-bottom: 1px #18394d solid;
    cursor: pointer;
  }
  .topmain-row3-col11 {
    width: 7.2vw;
    height: 3.89vh;
    line-height: 3.89vh;
    cursor: pointer;
    //  border-bottom:1px #18394d solid
  }
  .boxdiv{
    width:1vw;
    height: 1vh;
    margin-left:1vw;
  }
}
::v-deep{
  .el-switch.is-disabled .el-switch__core{
                cursor: pointer !important;
            }
   .el-switch.is-disabled{
                           opacity: 1 !important;
       }
}
</style>